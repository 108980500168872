import React, {useState} from 'react';
import {LineItemSection} from '../LineItemSection/LineItemSection';
import {CouponInput} from '../CouponInput/CouponInput';
import {GiftCardInput} from '../GiftCardInput/GiftCardInput';
import {useControllerProps} from '../../Widget/ControllerContext';
import {classes} from '../Summary.st.css';
import {TotalsSection} from '../TotalsSection/TotalsSection';
import {ViolationsAndPlaceOrderButton} from '../../ViolationsAndPlaceOrderButton/ViolationsAndPlaceOrderButton';
import {StepId, ViolationsAndPlaceOrderButtonLocation} from '../../../../types/app.types';
import {SecureCheckout} from '../SecureCheckout/SecureCheckout';
import {CheckoutSlot} from '../../CheckoutSlot';
import {SlotId} from '../../../../domain/utils/slotId';
import {SPECS} from '../../constants';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {BottomFoldableSummarySection} from '../BottomFoldableSummarySection/BottomFoldableSummarySection';
import {isLineItemErrorViolationExist} from '../LineItem/LineItem.utils';

export enum BottomMobileSummaryDataHooks {
  placeOrderOpen = 'BottomMobileSummaryDataHooks.placeOrderOpen',
  placeOrderEmpty = 'BottomMobileSummaryDataHooks.placeOrderEmpty',
  paymentAndPlaceOrderStep = 'BottomMobileSummaryDataHooks.paymentAndPlaceOrderStep',
  placeOrderFastFlow = 'BottomMobileSummaryDataHooks.placeOrderFastFlow',
}
export interface BottomMobileSummaryProps {
  dataHook: string;
  checkboxesRef?: React.RefObject<HTMLDivElement>;
}

export const BottomMobileSummary = ({dataHook, checkboxesRef}: BottomMobileSummaryProps) => {
  const {experiments} = useExperiments();
  const {
    checkoutStore: {checkout, shouldShowGiftCardSection, shouldShowCouponSection, isFastFlow, shouldShowViolations},
    stepsManagerStore: {activeStep},
  } = useControllerProps();

  const shouldShowViolationsAndPlaceOrderButton =
    isFastFlow || activeStep.stepId === StepId.placeOrder || activeStep.stepId === StepId.paymentAndPlaceOrder;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const addSlotToCheckout = !isFastFlow && experiments.enabled(SPECS.AddSlotToCheckout);
  const hasLineItemIssue =
    !!checkout.errors.noItemsError || (shouldShowViolations && isLineItemErrorViolationExist(checkout.violations));
  const [isBottomFoldableSummarySectionOpen, setIsBottomFoldableSummarySectionOpen] = useState(hasLineItemIssue);
  const toggleFoldableSummarySection = () => {
    setIsBottomFoldableSummarySectionOpen(!isBottomFoldableSummarySectionOpen);
  };
  return (
    <div data-hook={dataHook} className={classes.bottomMobileSummary}>
      {addSlotToCheckout && (
        /* istanbul ignore next: test slot */ <CheckoutSlot
          className={classes.BeforeSummaryCheckoutSlot}
          slotId={SlotId.BeforeSummaryCheckoutSlot}
        />
      )}
      <BottomFoldableSummarySection
        onClick={toggleFoldableSummarySection}
        isOpen={isBottomFoldableSummarySectionOpen}
      />
      <div className={classes.summaryWithFoldableSection}>
        {isBottomFoldableSummarySectionOpen && <LineItemSection />}
        {addSlotToCheckout && (
          /* istanbul ignore next: test slot */ <CheckoutSlot
            className={classes.AfterLineItemsSummaryCheckoutSlot}
            slotId={SlotId.AfterLineItemsSummaryCheckoutSlot}
          />
        )}
        {addSlotToCheckout && (
          /* istanbul ignore next: test slot */ <CheckoutSlot
            className={classes.AfterLineItems2SummaryCheckoutSlot}
            slotId={SlotId.AfterLineItems2SummaryCheckoutSlot}
          />
        )}
        <div className={classes.couponAndGiftCard}>
          {shouldShowCouponSection && <CouponInput />}
          {shouldShowGiftCardSection && <GiftCardInput />}
        </div>
        {addSlotToCheckout && (
          /* istanbul ignore next: test slot */ <CheckoutSlot
            className={classes.BeforeTotalsBreakdownSummaryCheckoutSlot}
            slotId={SlotId.BeforeTotalsBreakdownSummaryCheckoutSlot}
          />
        )}
        <TotalsSection />
      </div>
      {shouldShowViolationsAndPlaceOrderButton && (
        <ViolationsAndPlaceOrderButton
          location={ViolationsAndPlaceOrderButtonLocation.summary}
          checkboxesRef={checkboxesRef}
        />
      )}
      <SecureCheckout />
      {addSlotToCheckout && (
        /* istanbul ignore next: test slot */ <CheckoutSlot
          className={classes.AfterSummaryCheckoutSlot}
          slotId={SlotId.AfterSummaryCheckoutSlot}
        />
      )}
    </div>
  );
};

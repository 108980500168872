import React, {useState} from 'react';
import {classes} from './Summary.st.css';
import {useControllerProps} from '../Widget/ControllerContext';
import {TotalsSection} from './TotalsSection/TotalsSection';
import {CouponInput} from './CouponInput/CouponInput';
import {GiftCardInput} from './GiftCardInput/GiftCardInput';
import {FoldableSummarySection} from './FoldableSummarySection/FoldableSummarySection';
import {SummaryTitle} from './SummaryTitle/SummaryTitle';
import {LineItemSection} from './LineItemSection/LineItemSection';
import {BiFoldableSummaryAction, BiMobilePosition} from '../../../domain/utils/bi.util';

export const TopMobileSummary = () => {
  const {
    checkoutStore: {shouldShowGiftCardSection, shouldShowCouponSection, onMobileFoldableSummaryToggle},
  } = useControllerProps();

  const [isFoldableSummarySectionOpen, setIsFoldableSummarySectionOpen] = useState(false);
  const toggleFoldableSummarySection = () => {
    const isSummaryOpen = !isFoldableSummarySectionOpen;
    setIsFoldableSummarySectionOpen(isSummaryOpen);
    onMobileFoldableSummaryToggle({
      origin: 'top',
      action: isSummaryOpen ? BiFoldableSummaryAction.open : BiFoldableSummaryAction.close,
    });
  };

  return (
    <>
      <FoldableSummarySection onClick={toggleFoldableSummarySection} isOpen={isFoldableSummarySectionOpen} />
      {isFoldableSummarySectionOpen && (
        <div className={classes.summaryWithFoldableSection}>
          <SummaryTitle shouldShowNumberOfOrders={false} />
          <LineItemSection />
          <div className={classes.couponAndGiftCard}>
            {shouldShowCouponSection && <CouponInput mobilePosition={BiMobilePosition.top} />}
            {shouldShowGiftCardSection && <GiftCardInput mobilePosition={BiMobilePosition.top} />}
          </div>
          <TotalsSection />
        </div>
      )}
    </>
  );
};

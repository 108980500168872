import React from 'react';
import {FIELDS_GAP, FORMS_TEMPLATE_IDS, FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER, SPECS} from '../../constants';
import {FormViewer} from '@wix/form-viewer/widget';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {FormProps} from '../ContactForm/ContactForm';
import {useControllerProps} from '../../Widget/ControllerContext';
import {CashierMandatoryFieldsOverrides} from '../../../../types/app.types';
import {useICUI18n} from '../../Hooks/useICUI18n';
import {getAddressFormOverrides} from './addressForm.utils';
import {CountryCode} from '@wix/form-fields';

export interface AddressFormProps extends FormProps {
  overrides?: CashierMandatoryFieldsOverrides;
  isGroo?: boolean;
  enabledDeliveryCountries?: CountryCode[];
}

const AddressFormInternal = ({
  formRef,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  overrides,
  isGroo,
}: AddressFormProps) => {
  const {experiments} = useExperiments();
  const {
    checkoutSettingsStore: {checkoutSettings, checkoutComposerEnabled},
  } = useControllerProps();

  const i18n = useICUI18n();

  const shouldFilterByShippingCountries = experiments.enabled(SPECS.FilterCountriesByShippingCountries);

  return (
    <FormViewer
      i18n={i18n}
      ref={formRef}
      overrides={getAddressFormOverrides({
        experiments,
        checkoutSettings,
        overrides,
        checkoutComposerEnabled,
        isGroo,
        shouldFilterByShippingCountries,
      })}
      formId={
        checkoutComposerEnabled ? FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.ADDRESS : FORMS_TEMPLATE_IDS.ADDRESS
      }
      values={formValues}
      errors={formErrors}
      onChange={setFormValues}
      onValidate={setFormErrors}
      style={{
        styleParams: {
          numbers: {
            rowSpacing: FIELDS_GAP,
          },
        },
      }}
    />
  );
};

export const AddressForm = React.memo(AddressFormInternal);
